<template>
  <div v-if="contentDetailState.content.item && contentDetailState.content.item.id !== 117 && contentDetailState.content.item.id !== 118 && contentDetailState.content.item.id !== 122" class="bg-white pb-8">
    <HeroBar :bgSrc="getContentImageUrl(contentDetailState.content.item)">
      <div class="relative max-w-7xl mx-auto md:p-6">
        <div class="max-w-3xl mx-auto">
          <div v-if="contentDetailState.content.liveSession">
            <a @click.prevent="navigateToItem(contentDetailState.content.liveSession)" class="relative card-zoom cursor-pointer md:rounded-xl">
              <div class="card-zoom-image" :style="{ 'background-image' : 'url(' + getContentImageUrl(contentDetailState.content.item) + ')' }"></div>
              <div class="card-zoom-text flex">
                <h1 class="m-auto">
                  <button class="bg-red-500 hover:bg-red-600 py-2 px-3 rounded-md text-sm text-white font-bold">
                    <PlayIcon class="w-4 h-4 text-white m-auto inline" />
                    {{$filters.contentStatus(contentDetailState.content.liveSession.status)}}
                  </button>
                </h1>
              </div>
            </a>
          </div>
          <div v-else>
            <img :src="getContentImageUrl(contentDetailState.content.item)" alt="" class="md:rounded-xl ">
          </div>
        </div>
      </div>
    </HeroBar>
    <div class="text-center py-4 bg-blue">
      <button v-if="$filters.arrayItemsExist(subscriptionState.inPersonETickets)" @click.prevent="navigateToTicketOptions()" class="cursor-pointer inline-block bg-white rounded-lg px-4 py-2 text-lg font-bold text-blue text-center hover:bg-blue-dark hover:text-white justify-content-center">
        Register Now
      </button>
    </div>

    <div id="details-top" class="mx-auto max-w-7xl mt-12 px-6">
      <div class="mx-auto lg:mx-0 lg:max-w-5xl">
        <h2 class="font-display text-4xl font-bold tracking-tighter text-blue sm:text-5xl">{{
          contentDetailState.content.item.title }}
        </h2>
        <div>
          <p class="mt-4 font-display text-xl tracking-tight text-gray-500 sm:text-2xl">
            {{ contentDetailState.content.item.description }}
          </p>
          <!-- <p class="mt-2 font-display text-lg tracking-tight text-gray-500">
            Only the evening sessions will be streamed online - so come and join us!
          </p> -->
        </div>
      </div>
      <div class="mt-4">
        <button v-if="contentDetailState.content.showFAQs" class="text-xl font-semibold tracking-tight text-blue-dark" @click="goToElementById('faqs-top')">
          View FAQs
        </button>
      </div>
      <dl class="mt-4 sm:grid sm:grid-flow-col gap-x-10 gap-y-6 lg:auto-cols-auto lg:grid-flow-col lg:grid-cols-none lg:justify-start lg:text-left">
        <div v-if="contentDetailState.content.earliestSessionDate">
          <dt class="mt-1 font-mono text-sm text-blue">When</dt>
          <dd class="mt-0.5 text-xl sm:text-2xl font-semibold tracking-tight text-blue-dark">
            <span>{{ contentDetailState.content.earliestSessionDate.toFormat('d.MM.yy') }}</span>
            <span
              v-if="contentDetailState.content.latestSessionDate.startOf('day') > contentDetailState.content.earliestSessionDate.startOf('day')">
              - {{ contentDetailState.content.latestSessionDate.toFormat('d.MM.yy') }}</span>
          </dd>
        </div>
        <div v-if="contentDetailState.content.item.address">
          <dt class="mt-1 font-mono text-sm text-blue">Venue</dt>
          <dd v-html="$filters.contentAddress(contentDetailState.content.item.address)" class="mt-0.5 text-xl sm:text-2xl  font-semibold tracking-tight text-blue-dark"></dd>
        </div>
        <!-- <div>
          <dt class="font-mono text-sm text-blue">&nbsp;</dt>
          <dd class="mt-0.5 text-xl sm:text-2xl  font-semibold tracking-tight text-blue-dark">FAQs</dd>
        </div> -->
      </dl>
    </div>

    <div class="max-w-7xl mx-auto mt-12 px-6">
      <!-- Notify section -->
      <div v-if="contentDetailState.content.item && contentDetailState.content.item.notify && contentDetailState.content.item.notifyUrl">
        <div class="mx-auto mt-10 max-w-7xl sm:px-6 lg:px-8">
          <div
            class="relative isolate overflow-hidden bg-blue-dark px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32">
            <h2 class="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">Be
              notified when registration opens.</h2>
            <p class="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-300">Registration is not yet open,
              but we can let you know when it is!</p>
            <div class="mx-auto mt-10 flex justify-center max-w-md gap-x-4">
              <a :href="contentDetailState.content.item.notifyUrl"
                class="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-blue hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Notify
                me</a>
            </div>
            <!-- <svg viewBox="0 0 1024 1024" class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2" aria-hidden="true">
            <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fill-opacity="0.7" />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(512 512) rotate(90) scale(512)">
                <stop stop-color="#7775D6" />
                <stop offset="1" stop-color="#E935C1" stop-opacity="0" />
              </radialGradient>
            </defs>
          </svg> -->
          </div>
        </div>
      </div>
      <div v-else>
        <GroupSchedule></GroupSchedule>
      </div>
    </div>
  </div>

  <div v-if="contentDetailState.content.item && contentDetailState.content.item.id === 117" class="bg-white">
    <NazSchool></NazSchool>
  </div>

  <div v-if="contentDetailState.content.item && contentDetailState.content.item.id === 118" class="bg-white">
    <NetherlandsConf></NetherlandsConf>
  </div>

  <div v-if="contentDetailState.content.item && contentDetailState.content.item.id === 122" class="bg-white">
    <div class="relative pb-10">
      <div>
        <div class="relative">
          <div class="absolute inset-0">
            <!-- <video id="bg-vid" autoplay loop muted playsinline>
              <source src="/video/home-bg.mp4" type="video/mp4" />
            </video> -->
            <img class="w-full h-full object-cover" src="https://pslive.blob.core.windows.net/images/event/fa6ca0a0-a26e-11ef-a5c5-e5aae5f43355-event.jpg" alt="" />
            <div class="absolute inset-0" aria-hidden="true" style="background-image: linear-gradient(to left bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))" />
          </div>
          <div class="relative max-w-7xl mx-auto md:p-6">
            <div class="max-w-3xl mx-auto">
              <div>
                <img src="https://pslive.blob.core.windows.net/images/event/fa6ca0a0-a26e-11ef-a5c5-e5aae5f43355-event.jpg" alt="" class="md:rounded-xl">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative z-50 flex-none border-b py-2">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex flex-wrap items-center justify-center lg:flex-nowrap">
          <div class="flex space-x-2 md:space-x-4">
            <a @click.prevent="scrollToId('info-panel')" class="inline-flex justify-center rounded-xl bg-transparent p-2 md:p-4 text-sm md:text-base font-semibold text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500" href="#">
              Info
            </a>
            <a @click.prevent="scrollToId('faqs-panel')" class="inline-flex justify-center rounded-xl bg-transparent p-2 md:p-4 text-sm md:text-base font-semibold text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500" href="#">
              FAQs
            </a>
            <a @click.prevent="scrollToId('support-panel')" class="inline-flex justify-center rounded-xl bg-transparent p-2 md:p-4 text-sm md:text-base font-semibold text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500" href="#">
              Support
            </a>
            <a @click.prevent="navigateToTicketOptions()" class="inline-flex justify-center rounded-xl bg-blue p-2 md:p-4 text-sm md:text-base font-semibold text-white hover:bg-blue-dark focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500" href="#">
              <span class="hidden md:inline">Get your tickets</span>
              <span class="md:hidden">Get tickets</span>
            </a>
          </div>
        </div>
      </div>

      <!-- <div class="absolute inset-0 overflow-hidden bg-indigo-50 -bottom-14 -top-36">
        <img alt="" fetchpriority="high"
          width="918" height="1495" decoding="async" data-nimg="1"
          class="absolute top-0 left-0 translate-x-[-55%] translate-y-[-10%] -scale-x-100 sm:left-1/2 sm:translate-x-[-98%] sm:translate-y-[-6%] lg:translate-x-[-106%] xl:translate-x-[-122%]"
          style="color:transparent" src="/img/background.jpg">
        <div class="absolute inset-x-0 top-0 h-40 bg-gradient-to-b from-white"></div>
        <div class="absolute inset-x-0 bottom-0 h-40 bg-gradient-to-t from-white"></div>
      </div> -->

      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" id="info-panel">
        <!-- <img class="mb-20 rounded-xl" src="/img/prayer-storm-conference-2025-1920x1080.jpg" /> -->

        <h1 class="mt-10 lg:mt-15 font-display text-4xl font-bold tracking-tighter text-gray-900 sm:text-6xl">
          Prayer Storm Conference 2025</h1>
        <div class="mt-6 space-y-6 font-display text-2xl tracking-tight text-gray-600">
          <p>Join us for our largest conference to date! Summer 2025 is not to be missed, for adults and kids alike. The Prayer Storm team along with special guests will be taking over Manchester Central, as we worship and intercede for the nations. A main venue for adults, and an additional kids venue to host the Prayer Storm Kids Conference 2025 for those 7-16 years old!</p>
        </div>
        <dl class="mt-10 grid md:grid-cols-2 gap-x-10 gap-y-4 md:auto-cols-auto md:grid-flow-col md:grid-cols-none md:justify-start text-left">
          <div>
            <dt class="font-mono text-sm text-gray-600">When</dt>
            <dd class="mt-0.5 text-2xl font-semibold tracking-tight text-gray-900">21-23 August, 2025</dd>
          </div>
          <div>
            <dt class="font-mono text-sm text-gray-600">Venue</dt>
            <dd class="mt-0.5 text-2xl font-semibold tracking-tight text-gray-900">Manchester Central, Petersfield, M2 3GX, UK
            </dd>
          </div>
        </dl>
      </div>
    </div>
    <section id="speakers" aria-labelledby="speakers-title" class="py-10">
      <svg aria-hidden="true" width="0" height="0">
        <defs>
          <clipPath id=":R57qfja:-0" clipPathUnits="objectBoundingBox">
            <path
              d="M0,0 h0.729 v0.129 h0.121 l-0.016,0.032 C0.815,0.198,0.843,0.243,0.885,0.243 H1 v0.757 H0.271 v-0.086 l-0.121,0.057 v-0.214 c0,-0.032,-0.026,-0.057,-0.057,-0.057 H0 V0">
            </path>
          </clipPath>
          <clipPath id=":R57qfja:-1" clipPathUnits="objectBoundingBox">
            <path
              d="M1,1 H0.271 v-0.129 H0.15 l0.016,-0.032 C0.185,0.802,0.157,0.757,0.115,0.757 H0 V0 h0.729 v0.086 l0.121,-0.057 v0.214 c0,0.032,0.026,0.057,0.057,0.057 h0.093 v0.7">
            </path>
          </clipPath>
          <clipPath id=":R57qfja:-2" clipPathUnits="objectBoundingBox">
            <path
              d="M1,0 H0.271 v0.129 H0.15 l0.016,0.032 C0.185,0.198,0.157,0.243,0.115,0.243 H0 v0.757 h0.729 v-0.086 l0.121,0.057 v-0.214 c0,-0.032,0.026,-0.057,0.057,-0.057 h0.093 V0">
            </path>
          </clipPath>
        </defs>
      </svg>
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h2 id="speakers-title" class="font-display text-4xl font-bold tracking-tighter text-gray-900 sm:text-5xl">
          Guests &amp; Speakers
        </h2>
        <p class="mt-4 font-display text-2xl tracking-tight text-gray-600">
          We have an amazing lineup of worship leaders, speakers, and of course, the Prayer Storm team joining us this year.
        </p>
        <div class="mt-10 grid grid-cols-1 items-start gap-x-8 gap-y-8 sm:mt-16 sm:gap-y-16 lg:grid-cols-4">

          <div class="lg:col-span-4">
            <div class="grid grid-cols-1 gap-x-8 gap-y-10 ui-not-focus-visible:outline-none sm:grid-cols-2 sm:gap-y-16 md:grid-cols-3">
              <div v-for="speaker in speakers" :key="speaker.id">
                <div class="profile-img group relative transform overflow-hidden rounded-xl">
                  <div class="absolute inset-0">
                    <img alt=""
                      width="512" height="512"
                      class="absolute inset-0 h-full w-full object-cover transition duration-300 group-hover:scale-110"
                      :src="speaker.img">
                  </div>
                </div>
                <h3 class="mt-4 font-display text-xl font-bold tracking-tight text-slate-900">{{ speaker.name }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="schedule" aria-labelledby="schedule-title" class="py-10">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h2 class="font-display text-4xl font-bold tracking-tighter text-gray-900 sm:text-5xl">
          Schedule
        </h2>
        <p class="mt-4 font-display text-2xl tracking-tight text-gray-600">
          Three amazing days of worship, prayer and teaching.
          <br/>
          <em>**Early release schedule, subject to change.</em>
        </p>
        <!-- <div class="absolute inset-0 overflow-hidden bg-indigo-50 -bottom-32 -top-40"><img alt="" fetchpriority="high"
            width="918" height="1495" decoding="async" data-nimg="1"
            class="absolute top-0 left-full -translate-x-1/2 sm:left-1/2 sm:translate-x-[-20%] sm:translate-y-[-15%] md:translate-x-0 lg:translate-x-[5%] lg:translate-y-[4%] xl:translate-x-[27%] xl:translate-y-[-8%]"
            style="color:transparent" src="/img/background.jpg">
          <div class="absolute inset-x-0 top-0 h-40 bg-gradient-to-b from-white"></div>
          <div class="absolute inset-x-0 bottom-0 h-40 bg-gradient-to-t from-white"></div>
        </div> -->
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 sm:gap-x-4 lg:gap-x-8">
          <section>
            <ol role="list" class="rounded-xl mt-10 space-y-8 bg-white/60 px-10 py-14 text-center shadow-xl backdrop-blur border">
              <li>
                <h3 class="text-xl font-semibold tracking-tight text-gray-900">
                  21st August 2025
                </h3>
              </li>
              <li>
                <h4 class="text-lg font-semibold tracking-tight text-gray-900">Session 1</h4>
                <p class="mt-1 tracking-tight text-gray-900">Thursday Afternoon</p>
                <p class="mt-1 font-mono text-sm text-slate-500">14:00 - 16:30 BST</p>
              </li>
              <li>
                <h4 class="text-lg font-semibold tracking-tight text-gray-900">Session 2</h4>
                <p class="mt-1 tracking-tight text-gray-900">Thursday Evening</p>
                <p class="mt-1 font-mono text-sm text-slate-500">18:30 - 22:00 BST</p>
              </li>
            </ol>
          </section>
          <section>
            <ol role="list" class="rounded-xl mt-10 space-y-8 bg-white/60 px-10 py-14 text-center shadow-xl backdrop-blur border">
              <li>
                <h3 class="text-xl font-semibold tracking-tight text-gray-900">
                  22nd August 2025
                </h3>
              </li>
              <li>
                <h4 class="text-lg font-semibold tracking-tight text-gray-900">Session 3</h4>
                <p class="mt-1 tracking-tight text-gray-900">Friday Morning</p>
                <p class="mt-1 font-mono text-sm text-slate-500">10:00 - 12:30 BST</p>
              </li>
              <li>
                <h4 class="text-lg font-semibold tracking-tight text-gray-900">Session 4</h4>
                <p class="mt-1 tracking-tight text-gray-900">Friday Afternoon</p>
                <p class="mt-1 font-mono text-sm text-slate-500">14:00 - 16:30 BST</p>
              </li>
              <li>
                <h4 class="text-lg font-semibold tracking-tight text-gray-900">Session 5</h4>
                <p class="mt-1 tracking-tight text-gray-900">Friday Evening</p>
                <p class="mt-1 font-mono text-sm text-slate-500">18:30 - 22:00 BST</p>
              </li>
            </ol>
          </section>
          <section>
            <ol role="list" class="rounded-xl mt-10 space-y-8 bg-white/60 px-10 py-14 text-center shadow-xl backdrop-blur border">
              <li>
                <h3 class="text-xl font-semibold tracking-tight text-gray-900">
                  23rd August 2025
                </h3>
              </li>
              <li>
                <h4 class="text-lg font-semibold tracking-tight text-gray-900">Session 6</h4>
                <p class="mt-1 tracking-tight text-gray-900">Saturday Morning</p>
                <p class="mt-1 font-mono text-sm text-slate-500">10:00 - 12:30 BST</p>
              </li>
              <li>
                <h4 class="text-lg font-semibold tracking-tight text-gray-900">Session 7</h4>
                <p class="mt-1 tracking-tight text-gray-900">Saturday Afternoon</p>
                <p class="mt-1 font-mono text-sm text-slate-500">14:00 - 16:30 BST</p>
              </li>
              <li>
                <h4 class="text-lg font-semibold tracking-tight text-gray-900">Session 8</h4>
                <p class="mt-1 tracking-tight text-gray-900">Saturday Evening</p>
                <p class="mt-1 font-mono text-sm text-slate-500">18:30 - 22:00 BST</p>
              </li>
            </ol>
          </section>
        </div>
      </div>
    </section>

    <section id="accomodation" aria-labelledby="accomodation-title" class="py-10">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h2 class="font-display text-4xl font-bold tracking-tighter text-gray-900 sm:text-5xl">
          Accommodation in Manchester
        </h2>
        <p class="mt-4 font-display text-2xl tracking-tight text-gray-600">
          Marketing Manchester Convention Bureau is the official accommodation provider for Prayer Storm 2025
          Annual Conference.
        </p>
        <p class="mt-2 font-display text-lg text-gray-600">
          <strong>All rooms are held on an on-line system, allowing you to book and secure your own
          accommodation using either pro-forma invoices or credit/debit card. If you require any assistance
          with your reservation, support is available via the Accommodation Booking Team.</strong>
        </p>
        <p class="mt-2 font-display text-lg text-gray-600">
          To book accommodation for this event, please use the following link:<br/>
          <a class="text-blue font-bold hover:underline" href="https://book.passkey.com/e/50924225">https://book.passkey.com/e/50924225</a>
        </p>
        <p class="mt-2 font-display text-lg text-gray-600">
          Negotiated delegate rates will be available to book until the end of the day on <strong>Thursday 24th July
          2025</strong>, so please book as soon as possible to guarantee a room. If you have any queries about the
          website, please contact Marketing Manchester Convention Bureau (<a class="text-blue font-bold hover:underline" href="mailto:abs@visitmanchester.com">abs@visitmanchester.com</a>).
        </p>
        <p class="mt-2 font-display text-lg text-gray-600">
          <strong>About Manchester</strong>
        </p>
        <p class="mt-2 font-display text-lg text-gray-600">
          For more information on Manchester as a destination please log onto
          <a class="text-blue font-bold hover:underline" href="https://www.meetinmanchester.com/">
            https://www.meetinmanchester.com/
          </a>;
          here you will discover all things to do in Manchester - from
          events and attractions to fine dining and a great night out.
        </p>
        <p class="mt-2 font-display text-lg text-gray-600">
          Download a digital version of the
          <a class="text-blue font-bold hover:underline" href="https://manchestergrowthcouk.sharepoint.com/sites/MMC/Shared%20Documents/Conference%20Team/3.%20ABS/2.%20ABS%20docs%20-%20NON%20EVENT/ABS%20Account%20Management%20&%20Client%20Services%20Support%20Templates/CSA%20Templates/Templates/TEMPLATE%20-%20ABS%20Proposal%20Landscape%20-%20v2.doc">city center map</a>
        </p>
        <p class="mt-2 font-display text-lg text-gray-600">
          For city transport information please see
          <a class="text-blue font-bold hover:underline" href="http://www.tfgm.com/">http://www.tfgm.com/</a>
        </p>
        <p class="mt-2 font-display text-lg text-gray-600">
          <strong>Contacts</strong>
        </p>
        <p class="mt-2 font-display text-lg text-gray-600">
          Should you have any queries, please contact Manchester Convention Bureau at
          <a class="text-blue font-bold hover:underline" href="mailto:abs@visitmanchester.com">abs@visitmanchester.com</a>.
        </p>
      </div>
    </section>

    <section id="support-panel" aria-labelledby="accomodation-title" class="py-10">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h2 class="font-display text-4xl font-bold tracking-tighter text-gray-900 sm:text-5xl">
          Support
        </h2>
        <p class="mt-4 font-display text-2xl tracking-tight text-gray-600">
          We cannot make this happen without you! Please consider donating to help us reach our fundraising goal and keep ticket prices low.
        </p>
        <div class="mt-6 flex">
          <div class="w-1/3 hidden md:block">
            <img src="https://pslive.blob.core.windows.net/images/event/fa6ca0a0-a26e-11ef-a5c5-e5aae5f43355-event.jpg" class="w-full rounded-md" />
          </div>
          <div class="w-full md:w-3/4 md:pl-6">
            <div class="flex justify-between pb-4">
              <a class="font-bold text-lg hover:underline" href="/support/4/prayer-storm-conference-2025">
                Prayer Storm Conference 2025 Support
              </a>
              <div class="flex-none pt-2">
                <a class="bg-blue hover:bg-blue-dark rounded-md px-6 py-3 text-md font-semibold text-white" href="/support/4/prayer-storm-conference-2025">
                  Donate Now
                </a>
              </div>
            </div>
            <div class="overflow-hidden rounded-full bg-gray-200 mt-4">
              <div class="h-2 rounded-full bg-blue" style="width: 18%" />
            </div>
            <div class="flex justify-between my-4">
              <div class="mr-6">
                <div class="text-lg font-bold">£9,030</div>
                <div>Raised</div>
              </div>
              <div>
                <div class="text-lg font-bold">£50,000</div>
                <div class="text-right">Total Goal</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <ContentFAQsConf></ContentFAQsConf>

    <!-- <section id="sponsors" aria-label="Sponsors" class="py-20 sm:py-32">
            <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h2
                    class="mx-auto max-w-2xl text-center font-display text-4xl font-medium tracking-tighter text-gray-900 sm:text-5xl">
                    Current sponsorships for our workshops and speakers.</h2>
                <div
                    class="mx-auto mt-20 grid max-w-max grid-cols-1 place-content-center gap-x-32 gap-y-12 sm:grid-cols-3 md:gap-x-16 lg:gap-x-32">
                    <div class="flex items-center justify-center"><img alt="Transistor" loading="lazy" width="158"
                            height="48" decoding="async" data-nimg="1" style="color:transparent"
                            src="/_next/static/media/transistor.a1e38d49.svg"></div>
                    <div class="flex items-center justify-center"><img alt="Tuple" loading="lazy" width="105"
                            height="48" decoding="async" data-nimg="1" style="color:transparent"
                            src="/_next/static/media/tuple.2f54ed03.svg"></div>
                    <div class="flex items-center justify-center"><img alt="StaticKit" loading="lazy" width="127"
                            height="48" decoding="async" data-nimg="1" style="color:transparent"
                            src="/_next/static/media/statickit.2e06fcea.svg"></div>
                    <div class="flex items-center justify-center"><img alt="Mirage" loading="lazy" width="138"
                            height="48" decoding="async" data-nimg="1" style="color:transparent"
                            src="/_next/static/media/mirage.e12f40ad.svg"></div>
                    <div class="flex items-center justify-center"><img alt="Laravel" loading="lazy" width="136"
                            height="48" decoding="async" data-nimg="1" style="color:transparent"
                            src="/_next/static/media/laravel.6faebf7c.svg"></div>
                    <div class="flex items-center justify-center"><img alt="Statamic" loading="lazy" width="147"
                            height="48" decoding="async" data-nimg="1" style="color:transparent"
                            src="/_next/static/media/statamic.923101b6.svg"></div>
                </div>
            </div>
        </section> -->
    <!-- <section id="newsletter" aria-label="Newsletter">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div
          class="relative -mx-4 overflow-hidden bg-indigo-50 px-4 py-20 sm:-mx-6 sm:px-6 md:mx-0 md:rounded-xl md:px-16 xl:px-24 xl:py-36">
          <img alt="" loading="lazy" width="919" height="1351" decoding="async" data-nimg="1"
            class="absolute left-1/2 top-0 translate-x-[-10%] translate-y-[-45%] lg:translate-x-[-32%]"
            style="color:transparent" src="/img/background-newsletter.jpg">
          <div class="relative mx-auto grid max-w-2xl grid-cols-1 gap-x-32 gap-y-14 xl:max-w-none xl:grid-cols-2">
            <div>
              <p class="font-display text-4xl font-medium tracking-tighter text-gray-900 sm:text-5xl">Stay
                up to date</p>
              <p class="mt-4 text-lg tracking-tight text-gray-900">Get updates on all of our events and be
                the first to get notified when tickets go on sale.</p>
            </div>
            <form>
              <h3 class="text-lg font-semibold tracking-tight text-gray-900">Sign up to our newsletter
                <span aria-hidden="true">↓</span>
              </h3>
              <div
                class="mt-5 flex rounded-xl bg-white py-2.5 pr-2.5 shadow-xl shadow-blue-900/5 focus-within:ring-2 focus-within:ring-blue-900">
                <input type="email" required="" placeholder="Email address" aria-label="Email address"
                  class="-my-2.5 flex-auto bg-transparent pl-6 pr-2.5 text-base text-slate-900 placeholder:text-slate-400 focus:outline-none"><button
                  class="inline-flex justify-center rounded-xl bg-orange-500 p-4 text-base font-semibold text-white hover:bg-orange-600 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
                  type="submit"><span class="sr-only sm:not-sr-only">Sign up</span><span class="sm:hidden"><svg
                      aria-hidden="true" viewBox="0 0 24 24" class="h-6 w-6">
                      <path d="m14 7 5 5-5 5M19 12H5" fill="none" stroke="currentColor" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></span></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useContent } from '@/composables/useContent'
import GroupSchedule from '@/components/GroupSchedule.vue'
import ContentFAQsConf from '@/components/ContentFAQsConf.vue'
import NazSchool from '@/components/content-info/NazSchool.vue'
import NetherlandsConf from '@/components/content-info/NetherlandsConf.vue'
import HeroBar from '@/components/HeroBar.vue'
import store from '../store'
import { PlayIcon } from '@heroicons/vue/20/solid'
import { useMemberships } from '@/composables/useMemberships'
import { useETickets } from '@/composables/useETickets'
import { DateTime } from 'luxon'

export default defineComponent({
  name: 'ContentGroup',
  components: {
    GroupSchedule,
    ContentFAQsConf,
    HeroBar,
    PlayIcon,
    NazSchool,
    NetherlandsConf
  },
  setup () {
    const x = Math.floor(Math.random() * 3) + 2
    const imgSrc = '/img/banners/ps-banner-' + x + '.jpg'

    const router = useRouter()
    const route = useRoute()
    const contentGuid = ref(route.params.guid)
    if (!route.params.type) {
      if (route.path.indexOf('conference') >= 0) {
        route.params.type = 'conference'
      }
      if (route.path.indexOf('school') >= 0) {
        route.params.type = 'school'
      }
      if (route.path.indexOf('event') >= 0) {
        route.params.type = 'event'
      }
    }

    const contentType = ref(route.params.type)
    const { subscriptionState, contentDetailState, searchContentDetail, getContentImageUrl, goContent } = useContent()
    const { searchMemberships } = useMemberships()
    const { searchETickets } = useETickets()

    onMounted(() => {
      loadScreen(true)
    })

    onUnmounted(() => {
      clearScreenPolling()
    })

    const clearScreenPolling = () => {
      if (window.screenPolling) {
        clearInterval(window.screenPolling)
      }
    }
    const startScreenPolling = () => {
      // check if there are link items - no point polling if not!
      if (contentDetailState.content.sessionItems && contentDetailState.content.sessionItems.length > 0) {
        // check if all are on demand - no point polling if so!
        let allOD = true
        contentDetailState.content.sessionItems.forEach(linkItem => {
          if (linkItem.status !== 4) {
            allOD = false
          }
        })
        // start polling if so!
        if (!allOD) {
          window.screenPolling = setInterval(() => {
            loadScreen(false)
          }, 60000) // every minute
        }
      }
    }

    const navigateToTicketOptions = () => {
      if (contentDetailState.content.item) {
        router.push('/checkout/event/' + contentDetailState.content.item.id + '/options')
      }
    }

    const loadScreen = (initialLoad) => {
      const searchReq = {
        type: contentType.value,
        guid: contentGuid.value,
        expectUser: store.getters.isLoggedIn
      }
      searchContentDetail(searchReq, initialLoad)
        .then((result) => {
          if (initialLoad) {
            clearScreenPolling()
            startScreenPolling()
          }
        }).catch(() => {
          if (initialLoad) {
            alert('Failed to load the screen, please refresh and try again.')
          }
        })
    }

    const goToSchedule = () => {
      const scrollTo = document.getElementById('schedule-top')
      scrollTo.scrollIntoView({ behavior: 'smooth' })
    }

    const navigateToItem = (item) => {
      goContent(item, router, route)
    }

    const goToElementById = (elementId) => {
      const scrollTo = document.getElementById(elementId)
      scrollTo.scrollIntoView({ behavior: 'smooth' })
    }

    const buildSubscriptions = async () => {
      let eTicketData
      let memberships
      const subscriptions = []
      const eTickets = []
      const inPersonETickets = []

      if (contentDetailState.content.onlineMemberships && contentDetailState.content.onlineMemberships.length > 0) {
        if (!memberships) {
          memberships = await searchMemberships()
        }
        if (memberships && memberships.length > 0) {
          memberships.forEach(membership => {
            if (contentDetailState.content.onlineMemberships.indexOf(membership.id) >= 0) {
              membership.isEnabled = false
              if (contentDetailState.content.activeMemberships.indexOf(membership.id) >= 0) {
                membership.isEnabled = true
              }
              subscriptions.push(membership)
            }
          })
        }
      }

      if (contentDetailState.content.onlineETickets && contentDetailState.content.onlineETickets.length > 0) {
        if (!eTicketData) {
          eTicketData = await searchETickets()
        }
        if (eTicketData && eTicketData.length > 0) {
          eTicketData.forEach(eTicket => {
            if (contentDetailState.content.onlineETickets.indexOf(eTicket.id) >= 0) {
              eTicket.earlyBirdDateTime = DateTime.fromISO(eTicket.earlyBirdEndsAt)
              const now = DateTime.now()
              if (eTicket.earlyBirdDateTime > now) {
                eTicket.isEarlyBird = true
              } else {
                eTicket.isEarlyBird = false
              }
              eTicket.isEnabled = false
              if (contentDetailState.content.activeETickets.indexOf(eTicket.id) >= 0) {
                eTicket.isEnabled = true
              }
              eTickets.push(eTicket)
            }
          })
        }
      }

      if (contentDetailState.content.inPersonETickets && contentDetailState.content.inPersonETickets.length > 0) {
        if (!eTicketData) {
          eTicketData = await searchETickets()
        }
        if (eTicketData && eTicketData.length > 0) {
          eTicketData.forEach(eTicket => {
            if (contentDetailState.content.inPersonETickets.indexOf(eTicket.id) >= 0) {
              eTicket.earlyBirdDateTime = DateTime.fromISO(eTicket.earlyBirdEndsAt)
              const now = DateTime.now()
              if (eTicket.earlyBirdDateTime > now) {
                eTicket.isEarlyBird = true
              } else {
                eTicket.isEarlyBird = false
              }
              eTicket.isEnabled = false
              if (contentDetailState.content.activeETickets.indexOf(eTicket.id) >= 0) {
                eTicket.isEnabled = true
              }
              inPersonETickets.push(eTicket)
            }
          })
        }
      }

      subscriptionState.subscriptions = subscriptions
      subscriptionState.eTickets = eTickets
      subscriptionState.inPersonETickets = inPersonETickets
    }

    watch(
      () => contentDetailState.content.onlineMemberships,
      () => {
        buildSubscriptions()
      }
    )
    watch(
      () => contentDetailState.content.onlineETickets,
      () => {
        buildSubscriptions()
      }
    )

    const scrollToId = (elementId) => {
      setTimeout(() => {
        const scrollTo = document.getElementById(elementId)
        scrollTo.scrollIntoView({ behavior: 'smooth' })
      }, 200)
    }

    const speakers = [
      { id: 1, name: 'James Aladiran', org: 'Prayer Storm', img: '/img/profile/PROFILE_JAMES.jpg' },
      { id: 2, name: 'Rebecca Aladiran', org: 'Worship Team', img: '/img/profile/PROFILE_REBECCA.jpg' },
      { id: 3, name: 'Matt Varah Wilson', org: 'Prayer Storm', img: '/img/profile/PROFILE_MATT.jpg' },
      { id: 4, name: 'Lou Engle', org: 'Lou Engle Ministries', img: '/img/profile/PROFILE_LOU.jpg' },
      { id: 5, name: 'Karen Wheaton', org: 'The Ramp', img: '/img/profile/PROFILE_KAREN.jpg' },
      { id: 6, name: 'Arome Osayi', org: 'Remnant Christian Network', img: '/img/profile/PROFILE_AROME.jpg' }
      // { id: 6, name: 'Jeremy Riddle', org: 'Musician', img: '/img/profile/PROFILE_JEREMY.jpg' },
    ]

    return {
      speakers,
      imgSrc,
      contentGuid,
      contentType,
      subscriptionState,
      contentDetailState,
      getContentImageUrl,
      goToSchedule,
      goToElementById,
      navigateToItem,
      navigateToTicketOptions,
      scrollToId
    }
  }
})
</script>

<style scoped>
.card-zoom {
  @apply relative flex items-center justify-center overflow-hidden shadow-xl w-full;
  padding-bottom: 56.25%; /* 16:9 */
}
.card-zoom-image {
  @apply absolute inset-0 transition-all duration-500 ease-in-out transform bg-center bg-cover;
}
.card-zoom-text {
  @apply absolute inset-0 text-3xl font-black transition-all duration-500 ease-in-out transform scale-110 text-gray-50;
}
.card-zoom:hover .card-zoom-image {
  @apply scale-110;
}
.card-zoom:hover .card-zoom-text {
  @apply scale-100;
}

.profile-img {
  padding-bottom: 100%; /* 16:9 */
}
</style>
