<template>
<main>
<div class="mx-auto py-12 px-4 sm:px-6 bg-gray-200">

  <!-- Loading and Error Messages -->
  <p v-show="!formState.loadError && !formState.registration">Loading your registration details, please wait...</p>
  <ErrorMessage :formError="formState.loadError" class="mt-6"></ErrorMessage>

  <!-- Event Ticket Details-->
  <div v-if="formState.checkout">

    <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-3">
      <div class="mt-2">
        <img v-if="formState.ticket.imageUrl" :src="formState.ticket.imageUrl" alt="" class="rounded-xl" >
        <img v-else src="https://pslive.blob.core.windows.net/images/event/1920_placeholder.jpg" alt="" class="rounded-xl" >
      </div>
      <div class="mt-2">
        <div>
          <InfoMessage v-if="formState.checkout.cancelledAt" :message="'You cancelled this ticket on ' + $filters.dateTimeMed(formState.checkout.cancelledAt)"></InfoMessage>
          <InfoMessage v-else-if="formState.checkout.confirmedAt" :message="'You confirmed this ticket on ' + $filters.dateTimeMed(formState.checkout.confirmedAt)"></InfoMessage>
          <InfoMessage v-else :message="'Please review, update and confirm your ticket details in order to access the QR code ticket below.'"></InfoMessage>
        </div>
        <div class="text-center mt-2 p-3 rounded-xl border border-gray-300 bg-white">
        <h1 class="font-bold text-2xl lg:text-3xl">{{formState.event.eventTitle}}</h1>
        <h2 class="text-md mt-1">Starts: {{$filters.dateTimeMed(formState.event.eventDate)}}</h2>
        <p class="text-md mt-1 italic">Ordered by {{ formState.checkout.firstName }} {{ formState.checkout.lastName }} ({{ formState.checkout.email }})</p>
        <div v-if="formState.checkout.cancelledAt" class="mt-6 border-t border-gray-200 py-6">
          <div class="grid grid-cols-1 gap-y-3 sm:gap-x-4">
            <div class="">
              <div class="mt-4 bg-white border border-gray-300 rounded-lg shadow-sm">
                <dl class="pb-6 pt-3 px-4 space-y-6 sm:px-6">
                  <div>
                    <p class="my-1 text-md font-bold italic">This ticket was cancelled on </p>
                    <p class="my-1 text-md">{{ $filters.dateTimeMed(formState.checkout.cancelledAt) }}</p>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="formState.checkout.confirmedAt && (activeDelegateCount > 0)" class="mt-2">
          <div v-if="formState.ticket.id === 37 || formState.ticket.id === 38 || formState.ticket.id === 39 || formState.ticket.id === 40 || formState.ticket.id === 42 || formState.ticket.id === 43">
            <div class="p-2">
              <p class="mt-1 text-lg text-gray-500 italic">Thank you for confirming your ticket. QR codes will be issued closer to the time. We will update you via email, or please check back later.</p>
            </div>
          </div>
          <div v-else>
            <div class="p-2">
              <p class="mt-1 text-sm text-red-500 font-bold">Please ensure you bring the below QR code with you in order to register at the the event.</p>
              <p class="mt-1 text-sm text-gray-500 italic">You can print the QR code or save a screen shot of it on your mobile phone. We will need to scan the ticket at the event.</p>
            </div>
            <div class="p-2 grid grid-cols-1 lg:grid-cols-2">
              <div class="text-center">
                <div>
                  <p class="my-1 text-md font-bold italic">Delegate/s:</p>
                </div>
                <div v-for="(delegate) in formState.delegates" :key="delegate.id">
                  <p v-if="!delegate.cancelled" class="text-sm">
                    {{ delegate.firstName }} {{ delegate.lastName }}
                  </p>
                </div>
                <div>
                  <p class="my-1 text-md font-bold italic">Attending:</p>
                </div>
                <div v-if="formState.ticket.sessionAttendance">
                  <div v-if="attendanceSelectionCount <= 0">
                    <p class="text-red-500 font-bold">NO SESSIONS</p>
                  </div>
                  <div>
                    <div v-for="option in formState.attendanceOptions" :key="option.id">
                      <p v-if="option.attending" class="text-sm">
                        {{ $filters.dateTimeMed(option.publishedAt) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <QRCodeVue3
                  :width="300"
                  :height="300"
                  :value="'https://prayerstorm.org/registration-ticket/verify/' + registrationGuid"
                  :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
                  :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
                  :dotsOptions="{
                    type: 'square',
                    color: '#000000',
                    gradient: {
                      type: 'linear',
                      rotation: 0,
                      colorStops: [
                        { offset: 0, color: '#000000' },
                        { offset: 1, color: '#000000' }
                      ],
                    }
                  }"
                  fileExt="png"
                  myclass=""
                  imgclass="mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="formState.ticket.sessionAttendance">
            <div v-if="activeDelegateCount > 0 && attendanceSelectionCount > 0">
              <p class="my-1 text-md font-bold italic">I confirm that the {{ activeDelegateCount }} delegate/s named below,</p>
              <div v-for="(delegate) in formState.delegates" :key="delegate.id">
                <p v-if="!delegate.cancelled" class="text-sm">
                  {{ delegate.firstName }} {{ delegate.lastName }}
                </p>
              </div>
              <p class="my-1 text-md font-bold italic">will be attending {{ attendanceSelectionCount }} session/s,</p>
              <div v-for="option in formState.attendanceOptions" :key="option.id">
                <p v-if="option.attending" class="text-sm">
                  {{ $filters.dateTimeMed(option.publishedAt) }}
                </p>
              </div>
              <ErrorMessage :formError="formState.confirmTicketError" class="mt-6"></ErrorMessage>
              <button @click.prevent="confirmTicket" :disabled="formState.confirmTicketAction" type="button" class="mt-5 disabled:opacity-50 inline-flex w-full justify-center rounded-md border border-transparent bg-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2 sm:text-sm">Confirm Ticket</button>
            </div>
            <div v-else>
              <p v-if="activeDelegateCount <= 0" class="mt-1 text-sm font-bold italic">I confirm that no delegates will be attending '{{ formState.event.eventTitle }}', and that this ticket can be cancelled.</p>
              <p v-else-if="attendanceSelectionCount <= 0" class="mt-1 text-sm font-bold italic">I confirm that we will not attending any sessions at '{{ formState.event.eventTitle }}', and that this ticket can be cancelled.</p>
              <ErrorMessage :formError="formState.cancelTicketError" class="mt-6"></ErrorMessage>
              <button @click.prevent="cancelTicket" :disabled="formState.cancelTicketAction" type="button" class="mt-5 disabled:opacity-50 inline-flex w-full justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm">Cancel Ticket</button>
            </div>
          </div>
          <div v-else>
            <div v-if="activeDelegateCount > 0">
              <p class="my-1 text-md font-bold italic">I confirm that the {{ activeDelegateCount }} delegate/s named below,</p>
              <div v-for="(delegate) in formState.delegates" :key="delegate.id">
                <p v-if="!delegate.cancelled" class="text-sm">
                  {{ delegate.firstName }} {{ delegate.lastName }}
                </p>
              </div>
              <p class="my-1 text-md font-bold italic">will be attending.</p>
              <ErrorMessage :formError="formState.confirmTicketError" class="mt-6"></ErrorMessage>
              <button @click.prevent="confirmTicket" :disabled="formState.confirmTicketAction" type="button" class="mt-5 disabled:opacity-50 inline-flex w-full justify-center rounded-md border border-transparent bg-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2 sm:text-sm">Confirm Ticket</button>
            </div>
            <div v-else>
              <p v-if="activeDelegateCount <= 0" class="mt-1 text-sm font-bold italic">I confirm that no delegates will be attending '{{ formState.event.eventTitle }}', and that this ticket can be cancelled.</p>
              <ErrorMessage :formError="formState.cancelTicketError" class="mt-6"></ErrorMessage>
              <button @click.prevent="cancelTicket" :disabled="formState.cancelTicketAction" type="button" class="mt-5 disabled:opacity-50 inline-flex w-full justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm">Cancel Ticket</button>
            </div>
          </div>
        </div>
      </div>

      </div>
    </div>

    <div class="mt-6">
      <h2 class="font-bold text-xl">Delegate Tickets</h2>
      <p class="text-sm mt-1">You have {{ activeDelegateCount }} active delegates who can attend. <span v-if="cancelledDelegateCount > 0"> {{ cancelledDelegateCount }} delegates have been cancelled. </span></p>
    </div>
    <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8 mt-6">
      <li  v-for="(delegate) in formState.delegates" :key="delegate.id" class="overflow-hidden rounded-xl border border-gray-300 bg-white">
        <div class="flex items-center gap-x-4 border-b border-gray-900/5 p-4">
          <UserIcon class="h-8 w-8 flex-none" aria-hidden="true" />
          <div class="text-sm font-medium leading-6 text-gray-900">
            <div :class="[delegate.cancelled ? 'line-through' : '']">{{ delegate.firstName }} {{ delegate.lastName }}</div>
            <span v-if="delegate.cancelled" class="text-white bg-red-500 rounded-md py-1 px-2 text-xs font-medium">Cancelled</span>
            <span v-else class="text-white bg-green-500 rounded-md py-1 px-2 text-xs font-medium">Active</span>
            <span v-if="delegate.cancelled && (delegate.status === 3 || delegate.status === 4)" class="text-white bg-gray-500 rounded-md ml-2 py-1 px-2 text-xs font-medium">Refund</span>
            <span v-if="delegate.child" class="text-black bg-yellow-300 rounded-md ml-2 py-1 px-2 text-xs font-medium">Child</span>
          </div>
        </div>
        <dl class="-my-3 divide-y divide-gray-200 px-4 py-4 text-sm leading-6">
          <div class="flex justify-between gap-x-4 py-3">
            <dt>Ticket Type</dt>
            <dd>{{ delegate.ticketTitle}}</dd>
          </div>
          <div v-if="!delegate.cancelled" class="flex gap-x-4 py-3">
            <a @click.prevent="beginUpdateDelegate(delegate)" class="inline-block bg-gray-200 hover:bg-gray-300 cursor-pointer px-2 py-1 text-sm font-bold rounded-md border border-gray-300">Update</a>
            <a @click.prevent="beginCancelDelegate(delegate)" class="inline-block bg-gray-200 hover:bg-gray-300 cursor-pointer px-2 py-1 text-sm font-bold rounded-md border border-gray-300">Cancel</a>
          </div>
        </dl>
      </li>
    </ul>

    <div class="mt-6" v-if="formState.ticket.sessionAttendance">
      <h2 class="font-bold text-xl">Attendance ({{ attendanceSelectionCount }} of {{ formState.attendanceOptions.length }} Sessions)</h2>
      <p class="text-sm mt-1">Please select the sessions that you would like to attend. <span class="font-bold italic">The ability to update your attendance may not be available closer to the event.</span> Please keep this up to date to help us plan for maximum availability and attendance.</p>
    </div>

    <div v-if="formState.ticket.sessionAttendance" class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 mt-6">
      <div @click.prevent="attendanceUpdate(option)" v-for="option in formState.attendanceOptions" :key="option.id" :class="[option.attending === true ? 'bg-green-500 hover:bg-green-600' : 'bg-red-500 hover:bg-red-600', 'relative flex items-center space-x-3 rounded-lg border border-gray-300 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-white cursor-pointer']">
        <div class="flex-shrink-0">
          <CheckIcon v-if="option.attending === true" class="h-6 w-6 text-white inline-block mr-2"></CheckIcon>
          <XMarkIcon v-else class="h-6 w-6 text-white inline-block mr-2"></XMarkIcon>
        </div>
        <div class="min-w-0 flex-1">
          <div href="#" class="focus:outline-none">
            <span class="absolute inset-0" aria-hidden="true" />
            <p class="text-sm font-bold text-white">{{ option.title }}</p>
            <p class="truncate text-sm text-white">{{ $filters.dateTimeMed(option.publishedAt) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
</main>
<TransitionRoot as="template" :show="formState.openUpdateDelegate">
  <Dialog as="div" class="relative z-10" @close="formState.openUpdateDelegate = false">
    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    </TransitionChild>

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-sm sm:p-6">
            <div v-if="formState.selectedDelegate">
              <h3 class="text-lg leading-6 font-bold">Update Delegate</h3>
              <form class="grid grid-cols-1 gap-y-3 pt-3" id="edit-delegate-form" @submit.prevent="submitForm">
                <div>
                  <label for="first-name" class="block text-sm font-medium text-gray-700">First name</label>
                  <div class="mt-1">
                    <input v-model="formState.selectedDelegate.firstName" type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full text-sm rounded-md border-gray-300 py-2 px-3 shadow-sm focus:border-blue focus:ring-blue" />
                  </div>
                </div>
                <div>
                  <label for="last-name" class="block text-sm font-medium text-gray-700">Last name</label>
                  <div class="mt-1">
                    <input v-model="formState.selectedDelegate.lastName" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full text-sm rounded-md border-gray-300 py-2 px-3 shadow-sm focus:border-blue focus:ring-blue" />
                  </div>
                </div>
              </form>
              <!-- <div class="mt-2">
                <p class="text-sm text-gray-500">Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.</p>
              </div> -->
            </div>
            <ErrorMessage :formError="formState.updateDelegateError" class="mt-6"></ErrorMessage>
            <div class="mt-6">
              <button :disabled="formState.updateDelegateAction" type="button" class="disabled:opacity-50 inline-flex w-full justify-center rounded-md border border-transparent bg-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2 sm:text-sm" @click.prevent="updateDelegate">Update Delegate</button>
            </div>
            <div class="mt-3">
              <button type="button" class="inline-flex w-full justify-center bg-gray-300 hover:bg-gray-200 rounded-md border border-transparent px-4 py-2 text-base font-medium shadow-sm hover:text-blue-dark focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 sm:text-sm" @click.prevent="formState.openUpdateDelegate = false">Close</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </div>
  </Dialog>
</TransitionRoot>
<TransitionRoot as="template" :show="formState.openCancelDelegate">
  <Dialog as="div" class="relative z-10" @close="formState.openCancelDelegate = false">
    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    </TransitionChild>

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
            <div v-if="formState.selectedDelegateToCancel">
              <h3 class="text-lg leading-6 font-bold">Confirm Cancellation:</h3>
              <p class="text-sm italic mt-2">Please confirm that you want to cancel the delegate named below.</p>
              <p class="bg-gray-100 p-2 text-md font-bold mt-2 rounded-lg">{{ formState.selectedDelegateToCancel.firstName }} {{ formState.selectedDelegateToCancel.lastName }}</p>
              <div v-if="formState.ticket.refundable">
                <p class="text-sm font-bold mt-2">Refund:</p>
                <select v-model="formState.delegateRefund" id="delegate-refund" name="delegate-refund" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm">
                  <option value="">Please Specify...</option>
                  <option value="no">I do not require a refund, make it my contribution to this event</option>
                  <option value="yes">I require a refund, less the small transaction fee</option>
                </select>
              </div>
            </div>
            <ErrorMessage :formError="formState.cancelDelegateError" class="mt-6"></ErrorMessage>
            <div class="mt-6">
              <button type="button" class="inline-flex w-full bg-gray-300 justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium shadow-sm hover:text-blue-dark focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 sm:text-sm" @click.prevent="formState.openCancelDelegate = false">Close</button>
            </div>
            <div class="mt-3">
              <button :disabled="formState.cancelDelegateAction" type="button" class="disabled:opacity-50 inline-flex w-full justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm" @click.prevent="cancelDelegate">Confirm &amp; Cancel</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </div>
  </Dialog>
</TransitionRoot>
</template>

<script>
import axios from 'axios'
import { useStore } from 'vuex'
import { defineComponent, onMounted, reactive, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import QRCodeVue3 from 'qrcode-vue3'
import responseHelper from '@/helpers/response.helper'
import ErrorMessage from '@/components/ErrorMessage.vue'
import InfoMessage from '@/components/global/InfoMessage.vue'
import { ticketTypes } from '../lib/constants'
import {
  Dialog,
  TransitionChild,
  TransitionRoot
  // Menu,
  // MenuButton,
  // MenuItem,
  // MenuItems
} from '@headlessui/vue'

import {
  XMarkIcon,
  CheckIcon,
  // QuestionMarkCircleIcon,
  // EllipsisVerticalIcon,
  UserIcon
  // ChevronDownIcon
} from '@heroicons/vue/24/outline'

export default defineComponent({
  components: {
    ErrorMessage,
    InfoMessage,
    QRCodeVue3,
    XMarkIcon,
    CheckIcon,
    // QuestionMarkCircleIcon,
    // EllipsisVerticalIcon,
    UserIcon,
    // ChevronDownIcon,
    Dialog,
    TransitionChild,
    TransitionRoot
    // Menu,
    // MenuButton,
    // MenuItem,
    // MenuItems
  },
  setup () {
    const store = useStore()

    const route = useRoute()
    const registrationGuid = ref(route.params.guid)

    const formState = reactive({
      selectedDelegate: undefined,
      selectedDelegateToCancel: undefined,
      editMode: false,
      checkout: undefined,
      event: undefined,
      ticket: undefined,
      delegates: [],
      attendanceOptions: [],
      loadError: responseHelper.noError,
      formSubmitted: false,
      formError: responseHelper.noError,
      successMessage: '',
      saveSessionAction: false,
      saveSessionAttendanceAction: false,
      saveSessionError: responseHelper.noError,

      openUpdateDelegate: false,
      updateDelegateAction: false,
      updateDelegateError: responseHelper.noError,

      openCancelDelegate: false,
      cancelDelegateAction: false,
      cancelDelegateError: responseHelper.noError,

      confirmTicketAction: false,
      confirmTicketError: responseHelper.noError,

      cancelTicketAction: false,
      cancelTicketError: responseHelper.noError,

      delegateRefund: ''
    })

    onMounted(() => {
      axios.post(
        'checkout/details',
        {
          guid: registrationGuid.value
        }
      ).then((response) => {
        if (response?.data?.success && response?.data?.result) {
          formState.checkout = response.data.result.checkout
          formState.event = response.data.result.event
          formState.delegates = response.data.result.delegates
          formState.ticket = response.data.result.ticket
          formState.attendanceOptions = response.data.result.attendanceOptions
          console.log(formState.attendanceOptions)
          if (formState.ticket && formState.ticket.imageUrl) {
            formState.ticket.imageUrl = process.env.VUE_APP_BLOB_CONTAINER + '/images/' + formState.ticket.imageUrl
          }
        } else {
          formState.loadError = responseHelper.unexpectedResult
        }
      }).catch((error) => {
        formState.loadError = responseHelper.getMessageV2(error)
      })
    })

    const attendanceSelectionCount = computed(() => {
      var attendCount = 0
      if (formState && formState.attendanceOptions.length > 0) {
        for (var i = 0; i < formState.attendanceOptions.length; i++) {
          if ('attending' in formState.attendanceOptions[i] && formState.attendanceOptions[i].attending === true) {
            attendCount++
          }
        }
      }
      return attendCount
    })

    const activeDelegateCount = computed(() => {
      var tempCount = 0
      if (formState && formState.delegates.length > 0) {
        for (var i = 0; i < formState.delegates.length; i++) {
          if ('cancelled' in formState.delegates[i] && formState.delegates[i].cancelled !== true) {
            tempCount++
          }
        }
      }
      return tempCount
    })
    const cancelledDelegateCount = computed(() => {
      var tempCount = 0
      if (formState && formState.delegates.length > 0) {
        for (var i = 0; i < formState.delegates.length; i++) {
          if ('cancelled' in formState.delegates[i] && formState.delegates[i].cancelled === true) {
            tempCount++
          }
        }
      }
      return tempCount
    })

    const beginSessionEdit = () => {
      if (formState.attendanceOptions) {
        for (var i = 0; i < formState.attendanceOptions.length; i++) {
          if (formState.attendanceOptions[i].attending === null) {
            formState.attendanceOptions[i].attending = false
          }
        }
      }
      formState.editMode = true
    }

    const attendanceUpdate = async (attendanceRecord) => {
      let attendingUpdate
      if ('attending' in attendanceRecord) {
        attendingUpdate = !attendanceRecord.attending
      } else {
        attendingUpdate = true
      }

      try {
        // check if the action is running
        if (!formState.saveSessionAttendanceAction) {
          formState.saveSessionAttendanceAction = true
          store.commit('global/setLoadingModal', { open: true })

          const params = {
            guid: registrationGuid.value,
            eventSessionId: attendanceRecord.id,
            attending: attendingUpdate
          }

          // post the updated data
          const response = await axios.post(
            'checkout/updateAttendance/session',
            params
          )
          if (response?.data?.success && response?.data?.result) {
            formState.attendanceOptions = response.data.result
            formState.editMode = false
          } else {
            formState.saveSessionError = responseHelper.unexpectedResult
          }
        }
      } catch (error) {
        alert(error)
        formState.saveSessionError = responseHelper.getMessageV2(error)
      } finally {
        formState.saveSessionAttendanceAction = false
        store.commit('global/setLoadingModal', { open: false })
      }
    }

    const confirmTicket = () => {
      // check if the action is running
      if (!formState.confirmTicketAction) {
        formState.confirmTicketAction = true

        // post the confirmation
        axios.post(
          'checkout/confirmTicket',
          {
            guid: registrationGuid.value
          }
        ).then((response) => {
          if (response?.data?.success && response?.data?.result) {
            formState.checkout = response.data.result
          } else {
            formState.confirmTicketError = responseHelper.unexpectedResult
          }
        }).catch((error) => {
          formState.confirmTicketError = responseHelper.getMessageV2(error)
        }).finally(() => {
          formState.confirmTicketAction = false
        })
      }
    }

    const cancelTicket = () => {
      // check if the action is running
      if (!formState.cancelTicketAction) {
        formState.cancelTicketAction = true

        // post the cancellation
        axios.post(
          'checkout/cancelTicket',
          {
            guid: registrationGuid.value
          }
        ).then((response) => {
          if (response?.data?.success && response?.data?.result) {
            formState.checkout = response.data.result.checkout
            formState.delegates = response.data.result.delegates
            formState.attendanceOptions = response.data.result.attendanceOptions
          } else {
            formState.cancelTicketError = responseHelper.unexpectedResult
          }
        }).catch((error) => {
          formState.cancelTicketError = responseHelper.getMessageV2(error)
        }).finally(() => {
          formState.cancelTicketAction = false
        })
      }
    }

    const saveSessionEdit = () => {
      // check if the action is running
      if (!formState.saveSessionAction) {
        formState.saveSessionAction = true

        // post the updated data
        axios.post(
          'checkout/updateAttendance',
          {
            guid: registrationGuid.value,
            attendanceOptions: formState.attendanceOptions
          }
        ).then((response) => {
          if (response?.data?.success && response?.data?.result) {
            formState.attendanceOptions = response.data.result
            formState.editMode = false
          } else {
            formState.saveSessionError = responseHelper.unexpectedResult
          }
        }).catch((error) => {
          formState.saveSessionError = responseHelper.getMessageV2(error)
        }).finally(() => {
          formState.saveSessionAction = false
        })
      }
    }

    const toggleAttendance = (option) => {
      // stop execution if not editmode
      if (!formState.editMode) { return }

      if ('attending' in option) {
        option.attending = !option.attending
      } else {
        option.attending = true
      }
    }

    const beginUpdateDelegate = (delegate) => {
      if (!formState.openUpdateDelegate) {
        formState.selectedDelegate = JSON.parse(JSON.stringify(delegate))
        formState.openUpdateDelegate = true
        formState.updateDelegateError = responseHelper.noError
      }
    }

    const updateDelegate = () => {
      // check if the action is running
      if (!formState.updateDelegateAction) {
        formState.updateDelegateAction = true
        formState.updateDelegateError = responseHelper.noError
        setTimeout(() => {
          // post the updated data
          axios.post(
            'checkout/updateDelegate',
            {
              guid: registrationGuid.value,
              id: formState.selectedDelegate.id,
              firstName: formState.selectedDelegate.firstName,
              lastName: formState.selectedDelegate.lastName
            }
          ).then((response) => {
            if (response?.data?.success && response?.data?.result) {
              formState.delegates = response.data.result.delegates
              formState.openUpdateDelegate = false
              // close the modal
            } else {
              formState.updateDelegateError = responseHelper.unexpectedResult
            }
          }).catch((error) => {
            formState.updateDelegateError = responseHelper.getMessageV2(error)
          }).finally(() => {
            formState.updateDelegateAction = false
          })
        }, 1000)
      }
    }

    const beginCancelDelegate = (delegate) => {
      if (formState.ticket.refundable) {
        formState.delegateRefund = ''
      } else {
        formState.delegateRefund = 'no'
      }

      if (!delegate.cancelled && !formState.openCancelDelegate) {
        formState.selectedDelegateToCancel = JSON.parse(JSON.stringify(delegate))
        formState.openCancelDelegate = true
        formState.cancelDelegateError = responseHelper.noError
      }
    }

    const cancelDelegate = () => {
      // check if the action is running
      if (!formState.cancelDelegateAction) {
        formState.cancelDelegateAction = true
        formState.cancelDelegateError = responseHelper.noError

        let refund = false
        // check if the ticket is refundable, ensure a refund option is selected
        if (formState.ticket.refundable) {
          if (formState.delegateRefund !== 'no' && formState.delegateRefund !== 'yes') {
            formState.cancelDelegateAction = false
            formState.cancelDelegateError = responseHelper.createError('Please select a refund option', null)
            return
          } else {
            refund = formState.delegateRefund === 'yes'
          }
        }

        setTimeout(() => {
          // post the updated data
          axios.post(
            'checkout/cancelDelegate',
            {
              guid: registrationGuid.value,
              id: formState.selectedDelegateToCancel.id,
              refund: refund
            }
          ).then((response) => {
            if (response?.data?.success && response?.data?.result) {
              formState.delegates = response.data.result.delegates
              formState.openCancelDelegate = false
              // close the modal
            } else {
              formState.cancelDelegateError = responseHelper.unexpectedResult
            }
          }).catch((error) => {
            formState.cancelDelegateError = responseHelper.getMessageV2(error)
          }).finally(() => {
            formState.cancelDelegateAction = false
          })
        }, 1000)
      }
    }

    return {
      formState,
      registrationGuid,
      ticketTypes,
      attendanceSelectionCount,
      activeDelegateCount,
      cancelledDelegateCount,
      beginSessionEdit,
      toggleAttendance,
      saveSessionEdit,
      beginUpdateDelegate,
      beginCancelDelegate,
      updateDelegate,
      cancelDelegate,
      confirmTicket,
      cancelTicket,
      attendanceUpdate
    }
  }
})
</script>
